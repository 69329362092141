<template>
  <base-material-card>
    <v-card class="px-4">
      <tattooers-form
        :title="$t('edit', { name: $tc('tattooer') })"
        :tattooer="tattooer"
        @validated="updateTattooer"
      ></tattooers-form>
    </v-card>
  </base-material-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TattooerEdit",
  components: { TattooersForm: () => import("@/components/tattooer/Form") },
  mounted() {
    this.fetchTattooer();
    this.fetchTattooerStudioRelations();
    console.log(this.tattooer);
  },
  data() {
    return {
      tattooerSaved: false,
      validationErrors: [],
      tattooer: {},
    };
  },
  methods: {
    ...mapActions("tattooers", [
      "editTattooer",
      "getTattooer",
      "getTattooerStudioRelations",
    ]),
    fetchTattooer() {
      this.getTattooer(this.$route.params.id).then((response) => {
        console.log("RESP", response);
        this.tattooer = response;
        this.tattooer.types = response.type_artist.map((x) => x.id);
      });
    },

    updateTattooer() {
      //console.log("update");
      console.log("UT", this.tattooer);
      this.editTattooer({
        tattooer_id: this.$route.params.id,
        tattooer: this.tattooer,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("tattooer_edit_success"), "", "");
        }
        this.$router.back();
      });
    },
  },
};
</script>
